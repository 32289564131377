<template>
  <b-container class="pb-3" fluid="xl">
    <b-breadcrumb class="mt-3">
      <b-breadcrumb-item :to="{ name: 'HistoryEvent' }">
        歷史評鑑列表
      </b-breadcrumb-item>
      <b-breadcrumb-item
        :to="{ name: 'EventDashboard', params: { eventID: eventID } }"
      >
        <b-icon v-if="event.type === 'remote'" icon="cloud"></b-icon>
        {{ event.name }}
        <b-badge v-if="event.status === 'ongoing'" variant="success">
          進行中
        </b-badge>
        <b-badge v-if="event.status === 'done'" variant="secondary">
          已結束
        </b-badge>
        <b-badge v-if="event.status === 'editing'" variant="info">
          編輯中
        </b-badge>
      </b-breadcrumb-item>
      <b-breadcrumb-item
        :to="{
          name: 'EventDashboard',
          params: { eventID: eventID },
          hash: `#RoundCard-${round.id}`
        }"
      >
        {{ round.name }}
        <b-badge v-if="round.status === 'ongoing'" variant="success">
          進行中
        </b-badge>
        <b-badge v-if="round.status === 'done'" variant="secondary">
          已結束
        </b-badge>
        <b-badge v-if="round.status === 'editing'" variant="info">
          編輯中
        </b-badge>
      </b-breadcrumb-item>

      <b-breadcrumb-item active>分數檢討</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row>
      <b-col>
        <h3>{{ round.name }} 分數檢討</h3>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <b-table
          hover
          bordered
          responsive
          :fields="summaryTableField"
          :items="targetSummarys"
        ></b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <v-chart
          v-if="chartOption"
          style="height:20rem"
          :option="chartOption"
          :init-option="{ renderer: 'svg' }"
          autoresize
        />
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <div class="d-flex flex-wrap justify-content-start align-items-center">
          <h3 class="mb-0 mr-3">分數狀態控制表</h3>
          <p class="mb-0">
            分數狀態說明 :
            <b-badge class="m-1" variant="danger">尚未繳交成績</b-badge>
            <b-badge class="m-1" variant="primary">已繳交成績</b-badge>
            <b-badge class="m-1" variant="warning">
              等待重新繳交成績
            </b-badge>
            <b-badge class="m-1" variant="secondary">乎略此筆成績</b-badge>
          </p>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col>
        <b-table
          hover
          bordered
          striped
          responsive
          :items="finalScores"
          :fields="tableField"
        >
          <template #head(target_code)>
            豆號 \ 評審暗碼
          </template>

          <!-- 評審 header -->
          <template #head()="data">
            <b-btn
              :id="`scoreStatus-rater-${data.label}`"
              style="min-width:4rem"
              pill
              :disabled="round.status === 'done'"
              size="sm"
              variant="info"
            >
              {{ data.label }}
            </b-btn>
            <b-popover
              :target="`scoreStatus-rater-${data.label}`"
              triggers="click blur"
              placement="auto"
            >
              <template #title>
                <div class="d-flex align-items-center justify-content-around">
                  <p class="mb-0">
                    {{ `評審: ${data.label}` }}
                  </p>
                  <strong>指令</strong>
                </div>
              </template>
              <div
                class="d-flex"
                v-if="
                  scoreStatus
                    .filter(s => s.rater_code === data.label)
                    .every(s => s.status === 'submitted') ||
                    scoreStatus
                      .filter(s => s.rater_code === data.label)
                      .every(s => s.status === 'ignore')
                "
              >
                <b-btn
                  v-if="
                    scoreStatus
                      .filter(s => s.rater_code === data.label)
                      .every(s => s.status === 'submitted')
                  "
                  @click="
                    changeScoreStatus({ rater_code: data.label }, 'resubmit')
                  "
                  class="m-1"
                  size="sm"
                  variant="primary"
                  pill
                >
                  {{ `評審[${data.label}]成績全部重新評分` }}
                </b-btn>
                <b-btn
                  v-if="
                    scoreStatus
                      .filter(s => s.rater_code === data.label)
                      .every(s => s.status === 'submitted')
                  "
                  @click="
                    changeScoreStatus({ rater_code: data.label }, 'ignore')
                  "
                  class="m-1"
                  variant="primary"
                  pill
                  size="sm"
                >
                  {{ `乎略評審[${data.label}]全部成績` }}
                </b-btn>
                <b-btn
                  v-if="
                    scoreStatus
                      .filter(s => s.rater_code === data.label)
                      .every(s => s.status === 'ignore')
                  "
                  @click="
                    changeScoreStatus({ rater_code: data.label }, 'submitted')
                  "
                  class="m-1"
                  variant="primary"
                  pill
                  size="sm"
                >
                  {{ `採計評審[${data.label}]全部成績` }}
                </b-btn>
              </div>
              <div
                v-else
                class="d-flex align-items-center justify-content-center"
              >
                <p class="mb-0">無可用指令</p>
              </div>
            </b-popover>
          </template>

          <!-- 豆號  -->
          <template #cell(target_code)="data">
            <b-btn
              :id="`scoreStatus-target-${data.value}`"
              style="min-width:4rem"
              pill
              :disabled="round.status === 'done'"
              size="sm"
              variant="info"
            >
              {{ data.value }}
            </b-btn>
            <b-popover
              :target="`scoreStatus-target-${data.value}`"
              triggers="click blur"
              placement="auto"
            >
              <template #title>
                <div class="d-flex align-items-center justify-content-around">
                  <p class="mb-0">
                    {{ `豆號: ${data.value}` }}
                  </p>
                  <strong>指令</strong>
                </div>
              </template>
              <div
                class="d-flex"
                v-if="
                  scoreStatus
                    .filter(s => s.target_code === data.value)
                    .every(s => s.status === 'submitted') ||
                    scoreStatus
                      .filter(s => s.target_code === data.value)
                      .every(s => s.status === 'ignore')
                "
              >
                <b-btn
                  v-if="
                    scoreStatus
                      .filter(s => s.target_code === data.value)
                      .every(s => s.status === 'submitted')
                  "
                  @click="
                    changeScoreStatus({ target_code: data.value }, 'resubmit')
                  "
                  class="m-1"
                  size="sm"
                  variant="primary"
                  pill
                >
                  {{ `豆號[${data.value}]成績全部重新評分` }}
                </b-btn>
                <b-btn
                  v-if="
                    scoreStatus
                      .filter(s => s.target_code === data.value)
                      .every(s => s.status === 'submitted')
                  "
                  @click="
                    changeScoreStatus({ target_code: data.value }, 'ignore')
                  "
                  class="m-1"
                  variant="primary"
                  pill
                  size="sm"
                >
                  {{ `乎略豆號[${data.value}]全部成績` }}
                </b-btn>
                <b-btn
                  v-if="
                    scoreStatus
                      .filter(s => s.target_code === data.value)
                      .every(s => s.status === 'ignore')
                  "
                  @click="
                    changeScoreStatus({ target_code: data.value }, 'submitted')
                  "
                  class="m-1"
                  variant="primary"
                  pill
                  size="sm"
                >
                  {{ `採計豆號[${data.value}]全部成績` }}
                </b-btn>
              </div>
              <div
                v-else
                class="d-flex align-items-center justify-content-center"
              >
                <p class="mb-0">無可用指令</p>
              </div>
            </b-popover>
          </template>

          <!-- 分數 -->
          <template #cell()="data">
            <b-btn
              :disabled="round.status === 'done'"
              :variant="getScoreStatusVariant(data.value.status)"
              size="sm"
              :id="`scoreStatus-${data.value.rater_id}_${data.value.target_id}`"
              >{{
                data.value.final != null
                  ? data.value.final
                  : JSON.stringify(data.value)
              }}
            </b-btn>
            <b-popover
              :target="
                `scoreStatus-${data.value.rater_id}_${data.value.target_id}`
              "
              triggers="click blur"
              placement="auto"
            >
              <template #title>
                <div class="d-flex align-items-center justify-content-around">
                  <p class="mb-0">
                    {{
                      `評審: ${data.value.rater_code} 豆號: ${data.value.target_code}`
                    }}
                  </p>
                  <strong>指令</strong>
                </div>
              </template>
              <div
                class="d-flex"
                v-if="
                  data.value.status === 'submitted' ||
                    data.value.status === 'ignore'
                "
              >
                <b-btn
                  v-if="data.value.status === 'submitted'"
                  @click="
                    changeScoreStatus({ ids: [data.value.id] }, 'resubmit')
                  "
                  class="m-1"
                  size="sm"
                  variant="primary"
                  pill
                  >重新評分
                </b-btn>
                <b-btn
                  v-if="data.value.status === 'submitted'"
                  @click="changeScoreStatus({ ids: [data.value.id] }, 'ignore')"
                  class="m-1"
                  variant="primary"
                  pill
                  size="sm"
                >
                  乎略此成績
                </b-btn>
                <b-btn
                  v-if="data.value.status === 'ignore'"
                  @click="
                    changeScoreStatus({ ids: [data.value.id] }, 'submitted')
                  "
                  class="m-1"
                  variant="primary"
                  pill
                  size="sm"
                >
                  採計此成績
                </b-btn>
              </div>
              <div
                v-else
                class="d-flex align-items-center justify-content-center"
              >
                <p class="mb-0">無可用指令</p>
              </div>
            </b-popover>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-btn
          class="m-1"
          size="lg"
          variant="danger"
          :disabled="round.status === 'done'"
          v-b-modal="`modal-doneEvent-${roundID}`"
        >
          結束此輪評分
          <b-modal
            :id="`modal-doneEvent-${roundID}`"
            :title="`確認結束 ${round.name} 評分`"
            @ok="AlterRound('done')"
          >
            <p class="my-1">是否確認結束，結束後將無法修改、重啟</p>
          </b-modal>
        </b-btn>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { API } from "../store/api";
import randomColor from "randomcolor";
import ECharts from "vue-echarts";

import { use } from "echarts/core";
import { SVGRenderer } from "echarts/renderers";
import { ScatterChart } from "echarts/charts";
import {
  GridComponent,
  TitleComponent,
  MarkLineComponent,
  VisualMapPiecewiseComponent
} from "echarts/components";

use([
  SVGRenderer,
  ScatterChart,
  GridComponent,
  TitleComponent,
  MarkLineComponent,
  VisualMapPiecewiseComponent
]);

export default {
  name: "ScoreReview",
  components: { "v-chart": ECharts },
  props: {
    eventID: {
      type: Number,
      require: true
    },
    roundID: {
      type: Number,
      require: true
    }
  },
  computed: {
    chartOption() {
      if (Object.keys(this.round).length === 0) {
        return null;
      }

      return {
        title: { text: "總分散布圖", textAlign: "auto" },
        grid: {
          top: "70",
          right: "80",
          left: "100",
          bottom: "30"
        },
        xAxis: {
          name: "豆子暗碼",
          type: "category"
        },
        yAxis: {
          name: "總分",
          min: 60,
          max: 100
        },
        visualMap: [
          {
            text: ["評審暗碼"],
            showLabel: true,
            type: "piecewise",
            dimension: 2,
            categories: this.round.raters.map(r => r.code),
            inRange: {
              color: randomColor({
                count:
                  this.round.raters.length < 20 ? 20 : this.round.raters.length,
                luminosity: "dark",
                hue: "random",
                seed: this.eventID
              })
            }
          }
        ],
        series: [
          {
            symbolSize: 10,
            type: "scatter",
            data: this.scoreStatus
              .filter(obj => obj.status === "submitted")
              .map(obj => {
                return { value: [obj.target_code, obj.final, obj.rater_code] };
              }),
            label: {
              show: false
            },
            markLine: {
              data: [
                {
                  name: "80分線",
                  yAxis: 80,
                  lineStyle: {
                    // color: "red"
                  },
                  label: {
                    show: true,
                    formatter: "80分線"
                  }
                }
              ]
            }
          }
        ]
      };
    }
  },
  async mounted() {
    await this.dataUpdate();
  },
  methods: {
    async dataUpdate() {
      this.scoreStatus = await API.GetAllRatersRoundFinalStatus(this.roundID);
      let data = await API.GetRound(this.eventID, this.roundID);
      this.event = data.event;
      this.round = data.round;
      let raters = data.round.raters;
      let targets = data.round.targets;

      this.tableField = [
        ...this.tableField,
        ...data.round.rater_ids.map(r => {
          let rater_code = raters.find(t => t.id === r).code;
          return {
            key: rater_code,
            label: rater_code,
            sortable: true
          };
        })
      ];

      this.finalScores = data.round.target_ids.map(target_id => {
        let rowData = {
          target_code: targets.find(t => t.id === target_id).code
        };

        data.round.rater_ids.forEach(rater_id => {
          let rater_code = raters.find(r => r.id === rater_id).code;
          rowData[rater_code] = this.scoreStatus.find(
            o => o.rater_id === rater_id && o.target_id === target_id
          );
        });

        return rowData;
      });

      let summarys = await API.GetRatersCuppingSummary(data.round.id);
      this.targetSummarys = data.round.target_ids.map(id => {
        return {
          target_id: id,
          target_code: data.round.targets.find(t => t.id == id).code,
          ...summarys["" + id]
        };
      });
    },
    getScoreStatusVariant(status) {
      switch (status) {
        case "unsubmit":
          return "danger";
        case "submitted":
          return "primary";
        case "resubmit":
          return "warning";
        case "ignore":
          return "secondary";
      }
    },
    async changeScoreStatus(Obj, status) {
      let ScoreIDs = [];

      if (Obj.ids != undefined) {
        ScoreIDs = [...ScoreIDs, ...Obj.ids];
      }
      if (Obj.target_code != undefined) {
        let scoreStatuses = this.scoreStatus.filter(
          s => s.target_code == Obj.target_code
        );
        let ids = scoreStatuses.map(s => s.id);
        ScoreIDs = [...ScoreIDs, ...ids];
      }
      if (Obj.rater_code != undefined) {
        let scoreStatuses = this.scoreStatus.filter(
          s => s.rater_code == Obj.rater_code
        );
        let ids = scoreStatuses.map(s => s.id);
        ScoreIDs = [...ScoreIDs, ...ids];
      }

      switch (status) {
        case "submitted":
          await API.PUTCuppingScoreQueries(this.eventID, ScoreIDs, [], []);
          break;
        case "resubmit":
          await API.PUTCuppingScoreQueries(this.eventID, [], ScoreIDs, []);
          break;
        case "ignore":
          await API.PUTCuppingScoreQueries(this.eventID, [], [], ScoreIDs);
          break;
        default:
          console.log(`ileagal ScoreIDs:${ScoreIDs}  Status changing`);
      }
      await this.dataUpdate();
    },
    async AlterRound(status = "editing") {
      let res = await API.PUTRound(
        this.round.id,
        this.round.name,
        status,
        this.round.target_ids,
        this.round.rater_ids
      );

      if (res.status === 201) {
        this.$bvToast.toast(`${this.round.name}成功儲存輪資料`, {
          title: "通知",
          variant: "success",
          autoHideDelay: 500
        });
      }
      this.$router.push({
        name: "EventDashboard",
        params: { eventID: this.eventID }
      });
    }
  },
  data() {
    return {
      event: {},
      round: {},
      scoreStatus: [],
      targetSummarys: [],
      processingMode: undefined,

      tableField: [
        { key: "target_code", sortable: true, label: "豆號  評審暗碼" }
      ],
      finalScores: [],
      summaryTableField: [
        { key: "target_code", sortable: true, label: "豆號 暗碼" },
        { key: "max", sortable: true, label: "最大值" },
        { key: "min", sortable: true, label: "最小值" },
        { key: "avg", sortable: true, label: "平均" },
        { key: "std", sortable: true, label: "標準差" },
        { key: "trim_avg", sortable: true, label: "去極均" },
        { key: "trim_std", sortable: true, label: "去極標準差" }
      ]
    };
  }
};
</script>

<style lang="scss" scoped>
.progressModeBox {
  background: #e9ecef;
  box-shadow: -3px 3px 6px rgba(177, 179, 182, 0.2),
    3px -3px 6px rgba(177, 179, 182, 0.2),
    -3px -3px 6px rgba(255, 255, 255, 0.9), 3px 3px 8px rgba(177, 179, 182, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(177, 179, 182, 0.5);
  border-radius: 9px;
}
</style>
